<template>
  <iframe
    id="JotFormIFrame-210555676735059"
    title="Clear Mortgage Capital Mailer Approval Form"
    onload="window.parent.scrollTo(0,0)"
    allowtransparency="true"
    allowfullscreen="true"
    allow="geol ocation; microphone; camera"
    src="https://form.jotform.com/210555676735059"
    frameborder="0"
    style="min-width: 100%; height:539px; border:none;" scrolling="no">
  </iframe>
</template>
<script>
export default {
  name: 'MailerApprovalForm',
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
#JotFormIFrame-210555676735059 {
  height: 942px!important;
  display: block;
}
</style>
